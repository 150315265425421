const conf = {
    siteTitle: `GATSBY-STARTER-RESUME-MON DEMO`,
    siteUrl: `localhost:9090`,
    siteSubTitle: `gatsby-starter for one page resume.`,
    siteDescription: `gatsby-starter for one page resume.`,
    siteLanguage: `en`,

    googleAnalyticsCode: "",
}

exports.conf = conf