const contact = {
    accounts: [
        {
            service: "github.com",
            displayName: "aimof",
            link: "https://github.com/aimof",
        },
        {
            service: "gitlab.com",
            displayName: "aimof",
            link: "#",
        },
        {
            service: "Twitter.com",
            displayName: "#",
            link: "#",
        },
        {
            service: "Facebook.com",
            displayName: "#",
            link: "#",
        },
        {
            service: "My Blog",
            displayName: "#",
            link: "#",
        },
    ],
}

export default contact