const skills = [
    {
        "skill": "Go",
        "comment": "Go Go Go!",
    },
    {
        "skill": "Docker",
        "comment": "Whaaaaaaaale",
    },
    {
        "skill": "Gatsby",
        "comment": "Beginner",
    },
    {
        "skill": "#",
        "comment": "#",
    },
    {
        "skill": "#",
        "comment": "#",
    },
]

export default skills