const others = [
    {
        "title": "Why do you like Gatsby?",
        "body": "Because Gatsby taught me basic of react and made fun.",
    },
    {
        "title": "Do you like FizzBuzz?",
        "body": "Yes.",
    },
    {
        "title": "Foo",
        "body": "Bar",
    },
    {
        "title": "Foo",
        "body": "Bar",
    },
    {
        "title": "Foo",
        "body": "Bar",
    },

]

export default others